<div class="user-chat w-100 overflow-hidden">

  <div class="chat-content d-lg-flex">
    <!-- start chat conversation section -->
    <div class="w-100 overflow-hidden position-relative">
      <!-- conversation user -->
      <div class="position-relative">
        <div class="position-relative" id="users-chat">
          <ng-scrollbar class="chat-conversation p-3 p-lg-4" #chatScrollArea id="chatScrollArea">
            <ul class="list-unstyled chat-conversation-list" id="users-conversation">
              @for (data of comments; track $index) {
                <li class="chat-list left" [ngClass]="{ 'right': data.align === 'right' }">
                  <div class="conversation-list">
                    <div *ngIf="data.align === 'left'" class="avatar-xs rounded-circle shadow initials-avatar">
                      {{ data.first_name.charAt(0).toUpperCase() }}{{ data.last_name.charAt(0).toUpperCase() }}
                    </div>
                    <div class="user-chat-content">
                      <div class="ctext-wrap">
                        <div class="ctext-wrap-content">
                          @if(data.images.length){
                            <ul class="list-inline message-img mb-0">
                              @for(images of data.images;track $index){
                                <li class="list-inline-item message-img-list">
                                  <a class="d-inline-block m-1">
                                    <img src="{{images}}" alt=""
                                         class="rounded img-thumbnail" (click)="open($index, data.images)">
                                  </a>
                                </li>
                              }
                            </ul>
                          }
                          @if(data.message && data.align === 'right'){
                            <div class="dropdown" ngbDropdown placement="text-center">
                              <a href="javascript:void(0);" class="arrow-none" role="button"
                                 id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false"
                                 ngbDropdownToggle>
                                <i class="ri-more-2-fill"></i>
                              </a>

                              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink1" ngbDropdownMenu>
                                <li><a class="dropdown-item" (click)="openEditModal(data, EditMessageModal)">Edit</a></li>
                                <li><a class="dropdown-item" >Remove</a></li>
                              </ul>
                            </div>
                            <div *ngIf="data.message" class="body-message">
                              <p class="mb-0 ctext-content">{{ data.message }}</p>
                            </div>
                          } @else {
                            <div *ngIf="data.message" class="body-message">
                              <p class="mb-0 ctext-content">{{ data.message }}</p>
                            </div>
                          }
                        </div>
                      </div>
                      <div class="conversation-name {{data.align}}">
                        <small *ngIf="data.align === 'left'"
                               class="text-muted time">{{ data.first_name }} {{ data.last_name }}
                          - {{ data.role | titlecase }}</small>
                        <small class="text-muted time">
                          {{ data.updated_at | date:'MM/dd/yyyy hh:mm a' }}
                          <span *ngIf="data.edited" class="text-muted">- Edited</span>
                        </small>
                      </div>
                    </div>
                  </div>
                </li>


                <ng-template #EditMessageModal let-modal>
                  <div class="modal-body text-center p-5">
                    <div class="mt-4">
                      <h4 class="mb-3">Edit Message</h4>
                      <!-- Mostrar imagen si existe -->
                      <!-- <div *ngIf="selectedComment?.images.length > 0" class="mb-3">
                        <img *ngFor="let img of selectedComment?.images" [src]="img" class="img-thumbnail" alt="Image" style="max-width: 100%; max-height: 200px;">
                      </div> -->
                      <!-- Mostrar el mensaje actual -->
                      <p *ngIf="data.message" class="mb-3 ctext-content">{{ data.message }}</p>
                      <div class="chat-input-section pt-10">
                        <form (ngSubmit)="editComment()" [formGroup]="formEditMessage" id="chatinput-form"
                              enctype="multipart/form-data">
                          <div class="row g-0 align-items-center">
                            <div class="col">
                              @if (submittedMessage && messageForm['messageEdit'].errors) {
                                <div class="chat-input-feedback">
                                  @if (messageForm['messageEdit'].errors?.['required']) {
                                    <span>Please Enter a Message</span>
                                  }
                                </div>
                              }
                              <input type="text" class="form-control chat-input bg-light border-light"
                                     id="chat-input" placeholder="Type your message..." autocomplete="off"
                                     formControlName="messageEdit">
                            </div>
                            <div class="col-auto">
                              <div class="chat-input-links ms-2">
                                <div class="links-list-item">
                                  <button type="submit"
                                          class="btn btn-primary chat-send waves-effect waves-light shadow">
                                    <i class="ri-send-plane-2-fill align-bottom"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </ng-template>
              }
              <!-- chat-list -->
            </ul>
            <!-- end chat-conversation-list -->
          </ng-scrollbar>
          <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade"
               id="copyClipBoard" role="alert">
            Message copied
          </div>
        </div>
        <!-- end chat-conversation -->

        <div class="chat-input-section pt-10">

          <form (ngSubmit)="messageSave()" [formGroup]="formMessage" id="chatinput-form"
                enctype="multipart/form-data">
            <div class="row g-0 align-items-center">
              <div class="col-auto">
                <div class="chat-input-links me-2">
                  <div class="links-list-item">
                    <input type="file" id="attach-btn" (change)="attachImages($event)" multiple hidden #fileUpload accept=".jpg, .jpeg, .png">
                    <button type="button" class="btn btn-link text-decoration-none attach-btn"
                            (click)="document.getElementById('attach-btn')?.click()">
                      <i class="ri ri-attachment-2 align-middle"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col">
                @if (submittedMessage && messageForm['message'].errors) {
                  <div class="chat-input-feedback">
                    @if (messageForm['message'].errors?.['required']) {
                      <span>Please Enter a Message</span>
                    }
                  </div>
                }
                <input type="text" class="form-control chat-input bg-light border-light"
                       id="chat-input" placeholder="Type your message..." autocomplete="off"
                       formControlName="message">
              </div>
              <div class="col-auto">
                <div class="chat-input-links ms-2">
                  <div class="links-list-item">
                    <button type="submit"
                            class="btn btn-primary chat-send waves-effect waves-light shadow">
                      <i class="ri-send-plane-2-fill align-bottom"></i>
                    </button>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>

        <div *ngIf="imagePreviews.length" class="imageCard show">
          <div class="card mb-0">
            <div class="card-body py-3">
              <div class="replymessage-block mb-0 d-flex align-items-start">
                <div class="image-previews">
                  <img *ngFor="let preview of imagePreviews" [src]="preview" class="img-thumbnail small-preview" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
