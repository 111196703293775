import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FacadeService } from "../../../shared/services/facade/facade.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-policy-row',
  templateUrl: './policy-row.component.html',
  styleUrl: './policy-row.component.scss'
})
export class PolicyRowComponent implements OnChanges {
    @Input() permissions: any = [];
    @Input() processors: any = [];
    @Input() policies: any = [];
    @Input() isAdmin: boolean = false;
    @Input() isProcessor: boolean = false;
    @Input() current_user: any;
    @Output() onSort = new EventEmitter();
    @Output() onCheckboxChange = new EventEmitter();
    @Output() onChangePage = new EventEmitter();
    @Output() selectedPoliciesChange = new EventEmitter<any[]>();

    selectedPolicies: any[] = [];
    masterSelected!: boolean;

    paginatedPolicies: any[] = [];
    page = 1;
    pageSize = 10;
    totalPolicies = 0;
    duplicatedPolicy : any;
    pageSizes: number[] = [10, 25, 50, 100];

    policy_files: any[] = [];
    policy_comments: any[] = [];
    policy_internal_notes: any[] = [];

  constructor(public facadeService: FacadeService, private modalService: NgbModal) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['policies']) {
          this.policies = changes['policies'].currentValue.map((policy: any) => ({
            ...policy,
            isChecked: false
          }));

          this.totalPolicies = this.policies.length;
          this.updatePaginatedPolicies();
        }
    }

    checkUncheckAll(ev: any) {
      this.masterSelected = ev.target.checked;

      this.policies.forEach((policy: any) => {
        policy.isChecked = this.masterSelected;
      });

      this.selectedPolicies = this.masterSelected ? this.policies.map((policy: any) => policy.id) : [];
      this.selectedPoliciesChange.emit(this.selectedPolicies);
    }

    updatePaginatedPolicies() {
      const start = (this.page - 1) * this.pageSize;
      const end = start + this.pageSize;
      this.paginatedPolicies = this.policies.slice(start, end);
    }

    sort(field: string) {
        this.onSort.emit(field);
    }

    checkboxChange(event: any, policy: any) {
      policy.isChecked = event.target.checked;

      if (policy.isChecked) {
        this.selectedPolicies.push(policy.id);
      } else {
        this.selectedPolicies = this.selectedPolicies.filter(pId => pId !== policy.id);
      }
      this.selectedPoliciesChange.emit(this.selectedPolicies);
    }

    onPageChange(page: number) {
      this.page = page;
      this.updatePaginatedPolicies();
    }

    toChangePolicyState(id: string, status: string) {
        this.facadeService.policyService.changePolicyStatus(id, status).subscribe(() => {
            this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy ' + status + ' successfully' });
        });
    }

    onPageSizeChange(event: Event) {
      const selectElement = event.target as HTMLSelectElement;
      this.pageSize = +selectElement.value;
      this.page = 1;
      this.updatePaginatedPolicies();
    }

    accept_same_way_comment() {
      if (!this.duplicatedPolicy?.id) {
        console.error("Duplicated policy is not defined or does not have an ID.");
        return;
      }

      this.facadeService.spinner.show();

      this.facadeService.policyService.addPolicy(this.duplicatedPolicy, this.current_user, true, true).subscribe((policyId) => {
        if (!policyId) {
          throw new Error('Policy ID is not defined');
        }

        const comments$ = this.facadeService.commentsService.getCommentsByPolicy(this.duplicatedPolicy.id).pipe(
          switchMap((comments: any = []) => {
            const addCommentRequests = comments.map((comment: any) => {
              if (comment.images && comment.images.length > 0) {
                const copyImageRequests = comment.images.map((imageUrl: string) => {
                  const imageName = decodeURIComponent(imageUrl).split('/').pop()?.split('?')[0].split('_').pop();
                  const newPath = `${policyId}/${Date.now()}_${imageName}`;
                  return this.facadeService.storageFB.copyFile(imageUrl, newPath).then(newDownloadURL => newDownloadURL);
                });

                return forkJoin(copyImageRequests).pipe(
                  switchMap((newImageUrls: any) => {
                    const updatedComment = {
                      ...comment,
                      images: newImageUrls
                    };
                    return this.facadeService.commentsService.addComment(updatedComment, policyId);
                  })
                );
              } else {
                return this.facadeService.commentsService.addComment(comment, policyId);
              }
            });
            return forkJoin(addCommentRequests);
          })
        );

        const notes$ = this.facadeService.notesService.getNotesByPolicy(this.duplicatedPolicy.id).pipe(
          switchMap((notes: any = []) => {
            const addNoteRequests = notes.map((note: any) => this.facadeService.notesService.addNotes(policyId, note));
            return forkJoin(addNoteRequests);
          })
        );

        forkJoin([comments$, notes$]).subscribe();

        const copyFiles$ = this.facadeService.policyService.getPolicyFiles(this.duplicatedPolicy.id).pipe(
          switchMap((files: any = []) => {
            const copyFileRequests = files.map((file: any) => {
              const newPath = `${policyId}/${Date.now()}_${file?.name}`;
              return this.facadeService.storageFB.copyFile(file.downloadURL, newPath).then(newDownloadURL => {
                return this.facadeService.policyService.savePolicyFile(policyId, {
                  ...file,
                  downloadURL: newDownloadURL,
                  path: newPath
                });
              });
            });
            return forkJoin(copyFileRequests);
          })
        );

        this.facadeService.policyService.changePolicyStatus(this.duplicatedPolicy.id, 'cancelled').subscribe();

        copyFiles$.subscribe(() => {
          this.facadeService.spinner.hide();
          this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Policy duplicated successfully' });
        });
      });
    }

    cancelStatusChange(modal: any) {
      modal.close('Close click')
    }

    confirm_duplicate(data: any, NoticeSignModalDuplicate: any) {
      this.duplicatedPolicy = {...data} as any;

      this.modalService.open(NoticeSignModalDuplicate, { centered: true });
    }
}
