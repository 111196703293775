import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, } from '@angular/core';
import { Comment } from "../../../../shared/interfaces/comment";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from "@angular/forms";
import { SimplebarAngularModule } from 'simplebar-angular';
import { DatePipe, NgClass, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { FacadeService } from '../../../../shared/services/facade/facade.service';
import { NgScrollbar } from 'ngx-scrollbar';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs';
import { Lightbox } from 'ngx-lightbox';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { defineElement } from '@lordicon/element';
import lottie from 'lottie-web';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    SimplebarAngularModule,
    TitleCasePipe,
    NgClass,
    DatePipe,
    ReactiveFormsModule,
    NgIf,
    NgScrollbar,
    NgForOf,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() policyId: string | null = '';
  @Input() policy_comments: any[] = [];
  @Input() currentUser: any = {};
  @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
  @ViewChild('EditMessageModal') EditMessageModal: any;
  @Input() commentText: string | undefined;

  formMessage!: UntypedFormGroup;
  formEditMessage!: UntypedFormGroup;
  submittedMessage = false;
  comments: any[] = [];
  selectedFiles: File[] = [];
  imagePreviews: string[] = [];

  selectedComment: Comment | null = null;
  editedMessage: any;
  isEditing: boolean = false;
  showEditModal: boolean = false;
  form: FormGroup;

  constructor(private facadeService: FacadeService,
              private lightbox: Lightbox,
              private storage: AngularFireStorage, private modalService: NgbModal, private fb: FormBuilder) {
    defineElement(lottie.loadAnimation);
    this.form = this.fb.group({
      message: ['', Validators.required]
    });
    this.form = this.fb.group({
      messageEdit: ['', Validators.required]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.policyId) {
      return;
    }

    if(changes['policy_comments']) {
      this.comments = this.policy_comments;
      this.scrollToBottom();

      this.formEditMessage = new FormGroup({
        messageEdit: new FormControl('', Validators.required)
      });
    }
  }

  ngOnInit() {
    this.formMessage = new FormGroup({
      message: new FormControl('', Validators.required)
    });
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  get messageForm() {
    return this.formMessage.controls as any;
  }

  messageSave() {
    const message = this.formMessage.get('message')!.value;
    if (this.submittedMessage || (!message && this.selectedFiles.length === 0)) {
      return;
    }

    this.facadeService.spinner.show();

    this.submittedMessage = true;

    const uploadPromises = this.selectedFiles.map((file) => {
      const filePath = `${this.policyId}/${Date.now()}_${file.name}`;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);
      return task.snapshotChanges().pipe(
        finalize(async () => {
          return await fileRef.getDownloadURL().toPromise()
        })
      ).toPromise().then(async url => {
        return await fileRef.getDownloadURL().toPromise();
      });
    });

    Promise.all(uploadPromises).then((downloadURLs: any[]) => {
      let new_comment: Comment = {
        message: message,
        created_at: new Date().getTime(),
        updated_at: new Date().getTime(),
        read: false,
        role: 'agent',
        user_id: this.currentUser.id,
        first_name: this.currentUser.first_name,
        last_name: this.currentUser.last_name,
        policy: this.policyId || '',
        images: downloadURLs,
        edited : false
      };

      this.facadeService.commentsService.addComment(new_comment, this.policyId).subscribe(() => {
        this.submittedMessage = false;
        this.formMessage.reset();
        this.selectedFiles = [];
        this.imagePreviews = [];
        this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Note added successfully' });
        this.scrollToBottom();
        this.facadeService.spinner.hide();
      }, (error) => {
        this.facadeService.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error adding note' });
      });
    });
  }

  private scrollToBottom(): void {
    setTimeout(() => {
      if (this.scrollbarRef) {
        this.scrollbarRef.scrollTo({ bottom: 0, duration: 0 });
      }
    }, 100);
  }

  open(index: number, images: any): void {
    const _images = images.map((image: any) => {
      return {
        src: image,
        thumb: image,
        caption: '',
      };
    })

    this.lightbox.open(_images, index, {});
  }

  attachImages(event: any) {
    this.selectedFiles = Array.from(event.target.files);
    this.imagePreviews = [];
    this.selectedFiles.forEach(file => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imagePreviews.push(e.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  protected readonly document = document;

  private resetForm() {
    this.submittedMessage = false;
    this.formMessage.reset();
    this.formEditMessage.reset();
    this.selectedFiles = [];
    this.imagePreviews = [];
    this.selectedComment = null;
    this.isEditing = false;
  }

  openEditModal(comment: Comment, EditMessageModal:any) {
    this.isEditing = true;
    this.selectedComment = comment;
    this.formEditMessage.get('messageEdit')?.setValue(comment.message);
    this.modalService.open(EditMessageModal, { centered: true });
  }

  canEditComment(comment: Comment): boolean {
    return comment.user_id === this.currentUser.id;
  }


  editComment() {

    if (this.selectedComment && this.canEditComment(this.selectedComment) ) {
      const updatedMessage = this.formEditMessage.get('messageEdit')!.value;
      this.selectedComment.message = updatedMessage;
      this.selectedComment.edited = true;

      this.facadeService.commentsService.updateComment(this.selectedComment, this.policyId).subscribe(() => {
        this.facadeService.messageService.add({ severity: 'success', summary: 'Success', detail: 'Comment updated successfully' });
        this.resetForm();
        this.scrollToBottom();

      }, (error) => {
        this.facadeService.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error updating comment' });
      });
    } else {
      this.facadeService.messageService.add({ severity: 'error', summary: 'Error', detail: 'You cannot edit this comment.' });
    }
    this.modalService.dismissAll();
    this.resetForm();
  }
}
